import React from 'react';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';
import { Event, LegacyConversionBarProps } from '../../../types';
import { ConversionBarComponentProps } from './ConversionBar/ConversionBar';
import './ConversionBarContainer.scss';
import { ConversionBarContent } from './ConversionBarContent/ConversionBarContent';
import { Discounts } from './Discounts';

export interface ConversionBarContainerProps
    extends ConversionBarComponentProps,
        LegacyConversionBarProps {
    compactCheckoutDisqualifications: Event['compactCheckoutDisqualifications'];
    ticketsInfo: Event['ticketsInfo'];
    salesStatus: Event['salesStatus']['sales_status'];
    isSalesEnded: boolean;
    isSoldOut: boolean;
    childEvents: Event['childEvents'];
    eventId: Event['id'];
    onlyDefaultCheckoutForOnlineEvents: boolean;
    isOnlineEventMvpEnabled: boolean;
}

export const ConversionBarContainer: React.FC<ConversionBarContainerProps> = (
    props,
) => {
    const springRef = useSpringRef();
    const [containerSprings] = useSpring(
        () => ({
            ref: springRef,
            from: { transform: 'translateY(180px)', opacity: 0 },
            to: { transform: 'translateY(0)', opacity: 1 },
            config: {
                damping: 15,
                tension: 100,
                precision: 0.0001,
            },
        }),
        [],
    );
    const transRef = useSpringRef();
    const [signalSprings] = useSpring(
        () => ({
            ref: transRef,
            from: { transform: 'translateY(32px)' },
            to: { transform: 'translateY(0)' },
            config: {
                damping: 24,
                tension: 256,
            },
        }),
        [],
    );

    useChain([springRef, transRef], [0, 1], 1500);

    return (
        <animated.div
            style={containerSprings}
            className="conversion-bar-container"
        >
            <animated.div
                style={signalSprings}
                className="conversion-bar-signal"
            >
                <Discounts />
            </animated.div>
            <div className="conversion-bar-bordered">
                {props.children}
                <ConversionBarContent {...props} />
            </div>
        </animated.div>
    );
};
