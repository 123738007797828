import { track } from '@eventbrite/datalayer-library';
import { GoodToKnow as G2K, TrackingProvider } from '@eventbrite/good-to-know';
import { logEvent } from '@eventbrite/statsig';
import React, { useMemo } from 'react';
import { LISTING_HEAP_IDS } from '../../constants';
import { STATSIG_EVENT_NAMES, useG2KExperiment } from '../../experimentation';
import type { FaqItem } from '../EventDetails/Widgets/Faq';
import { type HighlightsProps } from '../Highlights';
import { useTrackComponent } from '../hooks/useTrackComponent';

type Props = {
    highlights: HighlightsProps;
    faqs: FaqItem[];
    refundPolicy: string;
    isOnline?: boolean;
    doorTime?: string;
};

export const GoodToKnow: React.FC<Props> = ({
    highlights,
    isOnline,
    doorTime,
    faqs,
    refundPolicy,
}) => {
    const g2kRef = React.useRef<HTMLDivElement>(null);
    const experimentActive = useG2KExperiment();

    useTrackComponent({
        ref: g2kRef,
        heap: {
            eventName: LISTING_HEAP_IDS.G2K_VIEW,
        },
        statsig: {
            eventName: STATSIG_EVENT_NAMES.G2K_VIEW,
        },
        onTrack: () => {
            if (highlights && Object.values(highlights).includes(true)) {
                track({
                    eventName: LISTING_HEAP_IDS.HIGHLIGHTS_VIEW,
                    eventData: {},
                });
                logEvent(STATSIG_EVENT_NAMES.HIGHLIGHTS_VIEW);
            }
            if (refundPolicy) {
                track({
                    eventName: LISTING_HEAP_IDS.REFUND_POLICY_VIEW,
                    eventData: {},
                });
                logEvent(STATSIG_EVENT_NAMES.REFUND_POLICY_VIEW);
            }
            if (faqs && faqs.length > 0) {
                track({
                    eventName: LISTING_HEAP_IDS.FAQ_VIEW,
                    eventData: {},
                });
                logEvent(STATSIG_EVENT_NAMES.FAQ_VIEW);
            }
        },
    });

    const dHighlights = useMemo(() => {
        return {
            ...highlights,
            isOnline,
            doorTime,
        };
    }, [highlights, isOnline, doorTime]);

    if (!experimentActive) {
        return null;
    }

    return (
        <>
            <TrackingProvider
                onSectionVisible={(sectionKey) => {
                    track({
                        eventName: LISTING_HEAP_IDS.G2K_VIEW_CARD,
                        eventData: {
                            card_key: sectionKey,
                        },
                    });
                    logEvent(STATSIG_EVENT_NAMES.G2K_VIEW_CARD, null, {
                        card_key: sectionKey,
                    });
                }}
                onReadMoreClick={(sectionKey) => {
                    track({
                        eventName: LISTING_HEAP_IDS.G2K_READ_MORE_CLICK,
                        eventData: {
                            card_key: sectionKey,
                        },
                    });
                    logEvent(STATSIG_EVENT_NAMES.G2K_READ_MORE_CLICK, null, {
                        card_key: sectionKey,
                    });
                }}
                onViewAllClick={() => {
                    track({
                        eventName: LISTING_HEAP_IDS.G2K_VIEW_ALL_CLICK,
                        eventData: {},
                    });
                    logEvent(STATSIG_EVENT_NAMES.G2K_VIEW_ALL_CLICK);
                }}
            >
                <G2K
                    highlights={dHighlights}
                    refundPolicy={refundPolicy}
                    faqs={faqs}
                />
            </TrackingProvider>
            <div ref={g2kRef}></div>
        </>
    );
};
