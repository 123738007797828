import { setLanguage } from '@eventbrite/i18n';
import {
    addDimensions,
    trackAnalyticsEvent,
    trackInitialPageView,
} from '@eventbrite/site-analytics';
import { logEvent } from '@eventbrite/statsig';
import React, { useEffect, useMemo } from 'react';
import { qualifiesForMultiticketSelection } from './components/Tickets/multiTicketsSelection';
import { Analytics } from './components/utils/analytics';
import { STATSIG_EVENT_NAMES } from './experimentation';
import { dataLayerMiddleware } from './tracking/dataLayerMiddleware';
import type { AppProps } from './types';
import { GACodes } from './types';

const setUpDimensions = (props: AppProps): void => {
    const {
        app_name: appName,
        app_version: appVersion,
        event,
        gaSettings,
    } = props;
    const {
        activeScopeUserId,
        correlationId,
        guestPartnerId,
        userPartnerId: userId,
        guestPartnerId: guestId,
    } = gaSettings;
    const {
        id: eventId,
        ownerId: eventOwnerUserId,
        source: eventSource,
    } = event;

    const dimensions = {
        activeScopeUserId,
        appName,
        appVersion,
        correlationId,
        eventId,
        eventOwnerUserId,
        eventSource,
        experimentId: 'OldListing',
        guestId,
        guestPartnerId,
        userId,
    };

    addDimensions(dimensions);
};

export const AppTracking: React.FC<AppProps> = (props: AppProps) => {
    const {
        env: {
            localeInfo: { locale },
        },
        event: { id: eventId, isOnlineEvent },
        tracking,
        // event_listing_response.tickets.ticketClasses
        event_listing_response: {
            tickets: { ticketClasses },
            featureFlags: { shouldShowOnlyDefaultCheckoutOnlineEvents },
        },
    } = props;

    setLanguage(locale);

    const onlyDefaultCheckoutForOnlineEvents =
        isOnlineEvent && shouldShowOnlyDefaultCheckoutOnlineEvents;

    setUpDimensions(props);

    Analytics({ ...tracking, eventId });

    useEffect(() => {
        trackInitialPageView(props.gaSettings, {
            dimensions: { eventId },
        });

        trackAnalyticsEvent({
            category: GACodes.GA_CATEGORY_LISTING,
            action: GACodes.GA_ACTION_APP_LOAD,
            label: props.user.canManageEvent
                ? GACodes.GA_LABEL_CAN_EDIT
                : GACodes.GA_LABEL_CANNOT_EDIT,
        });
        const eventQualifiesForMultiticketSelection =
            props.event && ticketClasses
                ? qualifiesForMultiticketSelection(
                      {
                          ...props.event,
                          salesStatus: props.event.salesStatus.sales_status,
                      },
                      ticketClasses,
                      props.event.ticketsInfo.hasBogoTickets,
                      onlyDefaultCheckoutForOnlineEvents,
                  )
                : false;
        logEvent(STATSIG_EVENT_NAMES.LISTING_APP_PAGE_VIEW, null, {
            multiticket: eventQualifiesForMultiticketSelection.toString(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useMemo(() => {
        // This method requires the use of `useMemo` otherwise tracking
        // in nested components may be called out of order and not receive
        // the page props intended to be set by the middleware.
        dataLayerMiddleware(
            locale,
            props.event,
            props.components,
            props.urgencySignals,
            props.user,
            props.event_listing_response,
            props.highlights,
        );
    }, [
        locale,
        props.event,
        props.components,
        props.urgencySignals,
        props.user,
        props.event_listing_response,
        props.highlights,
    ]);

    return <></>;
};
